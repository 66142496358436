<template>
  <div class="manager-table-header df jc-sb ai-c">
    <div class="manager-table-header__search-block">
      <div class="manager-table-header__inputs">
        <IqInput
          v-model="search"
          clearable
          placeholder="по номеру авто"
          suffix-name="IconSearch"
          @onKeydownEnter="handleEmitFilterParams"
          @onInput="debounceEmitFilterParams"
        />

        <IqSelect
          v-model="status"
          :options="statusesList"
          color-type-class="gray"
          class="m-l-20"
          is-multiple
          is-collapse-tags
          placeholder="Статус"
          @onVisibleChange="handleEmitFilterParams"
          @onRemoveTag="handleEmitFilterParams"
        />

        <IqSelect
          v-model="culture"
          :options="cultureList"
          color-type-class="gray"
          class="m-l-20"
          is-multiple
          is-collapse-tags
          placeholder="Культура"
          @onVisibleChange="handleEmitFilterParams"
          @onRemoveTag="handleEmitFilterParams"
        />
      </div>

      <div class="manager-table-header__inputs m-t-20">
        <IqSelect
          v-model="exporter"
          :options="exporterList"
          color-type-class="gray"
          is-multiple
          is-collapse-tags
          placeholder="Поставщик из квоты"
          @onVisibleChange="handleEmitFilterParams"
          @onRemoveTag="handleEmitFilterParams"
        />

        <IqSelectDate
          v-model="time_from"
          format="dd.MM.yyyy"
          placeholder="Дата с"
          class="m-l-20"
          color-type-class="gray"
          @onChange="handleEmitFilterParams"
        />

        <IqSelectDate
          v-model="time_to"
          format="dd.MM.yyyy"
          placeholder="Дата по"
          class="m-l-20"
          color-type-class="gray"
          @onChange="handleEmitFilterParams"
        />
      </div>

      <div class="manager-table-header__inputs m-t-20">
        <el-select
          v-model="quota_id"
          clearable
          class="iq-select gray quota-select"
          placeholder="Квота"
          :popper-append-to-body="false"
          popper-class="quota-select__dropdown"
          @change="handleEmitFilterParams"
        >
          <el-option
            v-for="item in quotasList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
            <div class="quota__dropdown">
              <span class="quota__dropdown-date">{{ item.date }}</span>
              <span class="quota__dropdown-day">{{ item.day }}</span>
              <span class="quota__dropdown-times">{{ item.times }}</span>
              <span class="quota__dropdown-exporter">{{ item.exporter }}</span>
              <span class="quota__dropdown-culture">{{ item.culture }}</span>
            </div>
          </el-option>
        </el-select>
      </div>
    </div>
  </div>
</template>

<script>
import { GET_TERMINAL_CURRENT_ID } from '@/store/actions'
import { GET_TERMINAL_QUOTA_FILTER } from '@/views/manager/store/actions'
import { debounce, pickerTzDate } from '@/core'
import { mapGetters } from 'vuex'
import { statusesList } from '@/views/manager/data/manager'
import IqInput from '@/UI/form/input/IqInput'
import IqSelect from '@/UI/select/IqSelect'
import IqSelectDate from '@/UI/select/IqSelectDate'

export default {
  name: 'ManagerTableHeader',
  components: { IqSelectDate, IqSelect, IqInput },
  props: {
    cultureList: { type: Array, default: () => [] },
    exporterList: { type: Array, default: () => [] },
    quotasList: { type: Array, default: () => [] },
  },
  data() {
    return {
      search: '',
      culture: null,
      exporter: null,
      status: null,
      time_from: null,
      time_to: null,
      quota_id: null,
      statusesList,
    }
  },
  computed: {
    ...mapGetters({
      currentTerminalId: GET_TERMINAL_CURRENT_ID,
      preparatoryQuotaFilter: GET_TERMINAL_QUOTA_FILTER,
    }),
  },
  watch: {
    currentTerminalId() {
      this.search = ''
      this.culture = null
      this.exporter = null
      this.status = []
      this.time_from = null
      this.time_to = null
      this.quota_id = null
    },
  },
  mounted() {
    if (this.preparatoryQuotaFilter) {
      this.quota_id = this.preparatoryQuotaFilter
    }
  },
  methods: {
    debounceEmitFilterParams() {
      debounce(this.handleEmitFilterParams, 1000)
    },
    handleEmitFilterParams() {
      this.$emit('emitFilterParams', {
        search: this.search,
        culture: this.culture,
        exporter: this.exporter,
        time_from: pickerTzDate(this.time_from),
        time_to: pickerTzDate(this.time_to, true),
        statuses: {
          not_stated: this.status.includes('not_stated'),
          parked_far: this.status.includes('parked_far'),
          parked_close: this.status.includes('parked_close'),
          arrived: this.status.includes('arrived'),
          late: this.status.includes('late'),
          detached: this.status.includes('detached'),
        },
        quota_id: this.quota_id,
      })
    },
  },
}
</script>

<style lang="sass">
.manager-table-header
  padding: $space-20 $space-16
  background: transparent
  border-radius: 4px 4px 0 0
  +to(1024px)
    flex-wrap: wrap

  &__inputs
    flex: 0 0 100%
    display: flex
    justify-content: flex-start
    align-items: center

    & > *
      flex: 1 0 calc((100% - 60px) / 3)

    .quota-select
      flex: 0 0 calc((100% - 40px) / 3 * 2 + 20px)
      width: unset !important
      z-index: 2
      .el-input__inner
        padding-right: 40px
        text-overflow: ellipsis
        overflow: hidden !important
        white-space: nowrap
      &__dropdown
        width: 100%

  &__search-block
    display: flex
    align-items: center
    justify-content: center
    flex: 1 0 calc(100% - 60px)
    flex-wrap: wrap

  &__wrap-btn-auto
    flex: 0 1 auto
    display: flex
    justify-content: flex-end
    align-items: center
    margin-left: 20px

.el-select-dropdown__item
  .quota__dropdown
    display: flex
    align-items: baseline
    width: 100%
    & > *
      flex-shrink: 0
    &-date
      width: 60px
      font-size: 16px
      font-weight: 500
      color: black
    &-day
      width: 100px
    &-times
      width: 150px
      font-weight: 500
    &-exporter
      width: calc((100% - 310px) / 2)
      overflow: hidden
      white-space: nowrap
      text-overflow: ellipsis
    &-culture
      width: calc((100% - 310px) / 2)
      overflow: hidden
      white-space: nowrap
      text-overflow: ellipsis

.el-select-dropdown__item.selected
  .quota__dropdown-date
    color: #409EFF
</style>
